import { useQuery } from "@apollo/react-hooks";
import { RouteComponentProps } from "@reach/router";
import { Divider, Spin, Table } from "antd";
import { ColumnProps } from "antd/lib/table";
import idx from "idx";
import moment from "moment";
import GET_BUYER_BY_ID from "./getBuyerById.graphql";
import {
  GetBuyerById,
  GetBuyerById_buyer as Buyer,
  GetBuyerById_buyer_consignees as Consignee,
  GetBuyerByIdVariables,
} from "./types/GetBuyerById";
import Authorize from "components/authorize";
import DescriptionsFor, { DescriptionItem } from "components/descriptionsFor";
import MainLayout from "components/layouts/main";
import withRouter from "components/withRouter";
import React, { FC, useMemo } from "react";
import routes, { BuyerViewParams } from "routes";
import { useErrorHandler, useNavigate } from "utils";

type Props = RouteComponentProps<BuyerViewParams>;

const BuyerView: FC<Props> = ({ id = "" }) => {
  const { data, loading, error } = useQuery<
    GetBuyerById,
    GetBuyerByIdVariables
  >(GET_BUYER_BY_ID, {
    variables: {
      id,
    },
  });

  const navigateToBuyers = useNavigate(routes.buyers);

  useErrorHandler(error, { callback: navigateToBuyers });

  const buyer = useMemo(() => idx(data, x => x.buyer), [data]);
  const items = useMemo<Array<DescriptionItem<Buyer>>>(
    () => [
      { key: "id", label: "Идентификатор" },
      { key: "name", label: "Наименование", span: 2 },
      { key: "address", label: "Адрес", span: 2 },
      { key: "inn", label: "ИНН" },
      { key: "contract", label: "Договор" },
      { key: "oldCode", label: "Код (для покупателей)" },
      { key: "code", label: "Код (внутренний)" },
      {
        key: "balance",
        label: "Сальдо",
        render: value => `${parseFloat(value).toFixed(2)} ₽`,
      },
      {
        key: "overdueSumm",
        label: "Сумма просроченной ДЗ",
        render: value => `${parseFloat(value).toFixed(2)} ₽`,
      },
      {
        key: "overdueDays",
        label: "Дней просрочки",
      },
      { key: "salesMember", label: "Торговый представитель" },
      { key: "salesMemberPhone", label: "Телефон торгового представителя" },
      {
        key: "creationTime",
        label: "Дата создания",
        render: value => moment(value).format("L LT"),
      },
      {
        key: "lastModificationTime",
        label: "Дата изменения",
        render: value => moment(value).format("L LT"),
      },
    ],
    []
  );

  const consigneeColumns = useMemo<Array<ColumnProps<Consignee>>>(
    () => [
      { dataIndex: "id", title: "#" },
      { dataIndex: "name", title: "Наименование" },
      { dataIndex: "inn", title: "ИНН" },
      { dataIndex: "address", title: "Адрес" },
      { dataIndex: "oldKod", title: "Код для покупателей" },
      { dataIndex: "rcd", title: "Старый код" },
      { dataIndex: "vrZak", title: "Время заказа" },
      { dataIndex: "zprOtgr", title: "Запрет отгрузки" },
    ],
    []
  );

  const normalizedName = (idx(buyer, x => x.name) || "").toUpperCase();

  return (
    <Authorize>
      <MainLayout title={normalizedName} onBack={navigateToBuyers}>
        <Spin spinning={loading}>
          <DescriptionsFor items={items} dataSource={buyer} />
        </Spin>
        <Divider />
        <div className="utils__title utils__title--flat my-3">
          <strong className="text-uppercase font-size-16">
            Грузополучатели
          </strong>
        </div>
        <Table<Consignee>
          columns={consigneeColumns}
          dataSource={idx(data, x => x.buyer.consignees) || []}
          loading={loading}
          pagination={false}
          rowKey="id"
        />
      </MainLayout>
    </Authorize>
  );
};

export default withRouter({ path: routes.buyerView })(BuyerView);
