import { Descriptions } from "antd";
import {
  DescriptionsItemProps,
  DescriptionsProps,
} from "antd/lib/descriptions";
import React, { ReactElement, ReactNode, useMemo } from "react";

export interface DescriptionItem<TDataSource>
  extends Partial<DescriptionsItemProps> {
  key: keyof TDataSource;
  render?: (
    value: TDataSource[keyof TDataSource],
    source: TDataSource
  ) => ReactNode;
}

interface DescriptionsForProps<TDataSource> {
  dataSource?: TDataSource | null;
  items?: Array<DescriptionItem<TDataSource>>;
}

type Props<TDataSource> = DescriptionsForProps<TDataSource> & DescriptionsProps;

const DescriptionsFor = <TDataSource extends {}>({
  dataSource,
  items = [],
  ...props
}: Props<TDataSource>): ReactElement => {
  const renderedItems = useMemo(
    () =>
      dataSource
        ? items.reduce<ReactNode[]>(
            (accumulator, { key, render, ...itemProps }, index) => {
              const value = dataSource[key];

              if (value) {
                accumulator.push(
                  <Descriptions.Item key={`${key}-${index}`} {...itemProps}>
                    {typeof render === "function"
                      ? render(value, dataSource)
                      : value}
                  </Descriptions.Item>
                );
              }

              return accumulator;
            },
            []
          )
        : null,
    [items, dataSource]
  );

  return <Descriptions {...props}>{renderedItems}</Descriptions>;
};

export default DescriptionsFor;
